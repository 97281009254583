.landing_main {
  width: 98vw;
  height: auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  .landing__inner {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 65px;
    .left_column{
      width: 46%;
    }
    .right_column{
      width: 46%;
    }
    .grid_item{
        width: 100%;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding:30px;
        border: 1px solid #cecece;
        border-radius: 6px;
        margin-top: 20px;
        .divi{
          display: flex;
          justify-content: space-between;
          >p:nth-child(1){
            font-size: 25px;
            font-family: 'Lato', sans-serif;
            font-weight: bold;
            padding-bottom: 30px;
        }
          >div:nth-child(2){
            background-color: rgb(221, 221, 25);
            height: 30px;
            padding:3px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            margin-top: 5px;
            >span{
              font-size: 14px;
              font-family: 'Lato', sans-serif;
              font-weight: bold;
              margin-top: -2px;
            }
        }
      }
    }
  }
}

@media (max-width:786px)
{
  .landing_main {
    width: 98vw;
    height: auto;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .landing__inner {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 65px;
      flex-direction: column-reverse;
      .left_column{
        width: 100%;
      }
      .right_column{
        width: 100%;
      }
      .grid_item{
          width: 100%;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          padding:30px;
          border: 1px solid #cecece;
          border-radius: 6px;
          margin-top: 20px;
          .divi{
            display: flex;
            justify-content: space-between;
            >p:nth-child(1){
              font-size: 25px;
              font-family: 'Lato', sans-serif;
              font-weight: bold;
              padding-bottom: 30px;
          }
            >div:nth-child(2){
              background-color: rgb(221, 221, 25);
              height: 30px;
              padding:3px 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 15px;
              margin-top: 5px;
              >span{
                font-size: 14px;
                font-family: 'Lato', sans-serif;
                font-weight: bold;
                margin-top: -2px;
              }
          }
        }
      }
    }
  }
}

