.NavbarMain{
  background: #F5F5F5;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top:0;
  z-index: 10;
}
.navbar__Left{
    display:flex;
    padding:12px 40px;
    column-gap: 25px;
    >button{
        border-radius: 6px;
        border: none;
        background-color:#808080;
        padding:5px;
        color:white;
        width: 100px;
        height: 35px;
        margin-top: -5px;
        cursor: pointer;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
    }
    >img{
        width: 100%;
        height: 100%;
    }
    >button:hover{
        border: 1px solid #808080;
        background-color: white;
        color: #808080;
    }
}
.navbar_right{
    display: flex;
    margin-right: 40px;
    position: relative;
    >p{
        font-family: 'Lato', sans-serif;
        font-weight: bolder;
        margin-top: 15px;
    }
    >i{
        font-size: 25px;
        margin-top: 15px;
        cursor: pointer;
    }
    .drop-down{
        position: absolute;
        background-color: white;
        padding: 5px;
        padding: 2px 20px;
        margin-top: 50px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 0px 0px 6px 6px;
        >p{
            font-family: 'Lato', sans-serif;
            font-weight: 500;
            padding: 5px 3px;
            color: black;
            text-decoration: none;
            margin-top: 10px;
        }
        >p:hover{
            background-color: #808080;
            cursor: pointer;
            color: white;
            border-radius: 6px;
        }
        .linkss{
            font-family: 'Lato', sans-serif;
            font-weight: 500;
            color: black;
            text-decoration: none;
            padding: 5px 3px;
            display: block;
            margin-top: 10px;
        }
        .linkss:hover{
            background-color: #808080;
            cursor: pointer;
            color: white;
            border-radius: 6px;
        }
    }
}

@media (max-width:768px)
{
    .NavbarMain{
        background: #F5F5F5;
        width: 100vw;
        height: 50px;
        display: flex;
        justify-content: space-between;
        position: fixed;
        top:0;
        z-index: 10;
      }
      .navbar__Left{
          display:flex;
          padding:12px 40px;
          column-gap: 25px;
        >img {
                width: 100px;
                height: 25px;
            }
      }
      .navbar_right{
          display: flex;
          margin-right: 40px;
          position: relative;
          >p{
              font-family: 'Lato', sans-serif;
              font-weight: bolder;
              margin-top: 15px;
              font-size: 12px;
          }
          >i{
              font-size: 20px;
              margin-top: 15px;
              cursor: pointer;
          }
          .drop-down{
              position: absolute;
              background-color: white;
              padding: 5px;
              padding: 2px 20px;
              margin-top: 50px;
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
              border-radius: 0px 0px 6px 6px;
              >p{
                  font-family: 'Lato', sans-serif;
                  font-weight: 500;
                  padding: 5px 3px;
                  color: black;
                  text-decoration: none;
                  margin-top: 10px;
              }
              >p:hover{
                  background-color: #808080;
                  cursor: pointer;
                  color: white;
                  border-radius: 6px;
              }
              .linkss{
                  font-family: 'Lato', sans-serif;
                  font-weight: 500;
                  color: black;
                  text-decoration: none;
                  padding: 5px 3px;
                  display: block;
                  margin-top: 10px;
              }
              .linkss:hover{
                  background-color: #808080;
                  cursor: pointer;
                  color: white;
                  border-radius: 6px;
              }
          }
      }
}