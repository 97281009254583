

.user_main{
    width: 100%;
    height: auto;
    margin-top: 55px;
    display: flex;
    justify-content: center;
    .users_inner{
        width: 95%;
        margin-top: 20px;
    }
}
.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .table-header {
        align-items: flex-start;
    }
    .user_main{
        width: 100%;
        height: auto;
        margin-top: 55px;
        display: flex;
        justify-content: center;
        .users_inner{
            width: 100%;
            margin-top: 20px;
        }
    }
}
.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud-demo .table-header {
        flex-direction: column;
    }
    .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
        width: 100%;
    }
}
.signature_main{
}
.image_main{
   height: 50px;
   border-right: 1.2px solid black;
   padding-right: 10px;
   margin-right: 10px;
   display: inline-block;
   vertical-align: top;
   width: 55.5%;
}

.this_is{
    display: inline-block;
    width: 40%;
    height: 60px;
}
.other_span{
    display: block;
    font-size: 9px;
    font-weight: 500;
    color: black;
    padding-top: 4px;
    margin-top: 6px;
    font-family: Helvetica;
    font-style: italic;
}
.some_span{
    display: block;
    font-size: 9px;
    font-weight: 500;
    font-family: Helvetica;
    color: black;
    font-style: italic;
    line-height: 5px;
}
.name_span{
    display: block;
    font-size: 9px;
    font-weight: 500;
    color: black;
    font-family: Helvetica;
    font-style: italic;
    line-height: 10px;
}